import React from "react";
import loadable from "@loadable/component";
import LayoutTwo from "../components/layoutTwo";

import PropertyDetailsImg1 from "../images/new_homes_banner_img.png";
import PropertyDetailsImg2 from "../images/property_details_img_2.png";
import PropertyDetailsImg3 from "../images/property_details_img_3.png";
import FloorPlanImg from "../images/floorplan.jpg";
import PropertyNegoImg from "../images/property_nego_img.png";
import GalleryImg1 from "../images/new_homes_gallery_img_1.png";
import GalleryImg2 from "../images/new_homes_gallery_img_2.png";
import GalleryImg3 from "../images/new_homes_gallery_img_3.png";
import GalleryImg4 from "../images/new_homes_gallery_img_4.png";
import GalleryImg5 from "../images/new_homes_gallery_img_5.png";
import GalleryImg6 from "../images/new_homes_gallery_img_6.png";
import PropertyImg1 from "../images/properties_img_1.png";
import PropertyImg2 from "../images/properties_img_2.png";
import PropertyImg3 from "../images/properties_img_3.png";

const PropertyBreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/PropertyBreadcrumbModule"));
const PropertyDetailsBanner = loadable(() => import("../components/PropertyDetailsBanner/PropertyDetailsBanner"));
const NewHomesDesc = loadable(() => import("../components/NewHomesDesc/NewHomesDesc"));
const NewHomesAvailability = loadable(() => import("../components/NewHomesAvailability/NewHomesAvailability"));
const NewHomesGallery = loadable(() => import("../components/NewHomesGallery/NewHomesGallery"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const SimilarProperties = loadable(() => import("../components/PropertySlider/SimilarProperties"));

const NewHomeDetails = () => {

    const similarProperty = [
        {
            title: 'St. Philips Avenue, Wolverhampton, WV3 7DU',
            beds: '3 bedroom detached house for sale',
            price: '£295,000',
            image: PropertyImg1
        },
        {
            title: 'Kings Road, Calf Heath, WV10 7DU',
            beds: '3 bedroom detached house for sale',
            price: '£495,000',
            image: PropertyImg2
        },
        {
            title: 'Rookery Lane, Wolverhampton, WV2 4PY',
            beds: '3 bedroom detached house for sale',
            price: '£345,000',
            image: PropertyImg3
        }
    ]

    const galleryImg = [
        { image: GalleryImg1 },
        { image: GalleryImg2 },
        { image: GalleryImg3 },
        { image: GalleryImg4 },
        { image: GalleryImg5 },
        { image: GalleryImg6 }
    ]

    return (
        <LayoutTwo>
            <div className="layout-padding-top"></div>

            <PropertyBreadcrumbModule />

            <PropertyDetailsBanner
                propertyDetailImg1={PropertyDetailsImg1}
                propertyDetailImg2={PropertyDetailsImg2}
                propertyDetailImg3={PropertyDetailsImg3}
                floorPlanImg={FloorPlanImg}
                tag="new-homes"
            />

            <NewHomesDesc
                propertyNegoImg={PropertyNegoImg}
            />

            <NewHomesAvailability />

            <NewHomesGallery newHomesGallery={galleryImg} />

            <PropertyDetailsMap
                lat={51.528607}
                lng={-0.4312419}
            />

            <SimilarProperties propertyDetail={similarProperty} />
        </LayoutTwo>
    )
}

export default NewHomeDetails