/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react";
import HeaderTwo from "./HeaderTwo/HeaderTwo";
import loadable from "@loadable/component";
import "animate.css/animate.min.css";
import "../styles/main.scss";
const Footer = loadable(() => import("./Footer/Footer"));

const LayoutTwo = ({ children, layout, footerContact, popularSearch }) => {

  return (
    <>
      <HeaderTwo 
        layout={layout}
      />
        <main>{children}</main>
      <Footer popularSearch={popularSearch} footerContact={footerContact}/>
    </>
  )
}

export default LayoutTwo